<template>
  <div class="page-general-info-2 animate__animated animate__fadeIn pt-4">
    <!-- <img :src="require('@/assets/banners/grl-info-2.png')" class="w-100 mb-4" alt="" /> -->
    <video
      v-if="!isMobile()"
      :src="`${urlBucket}/form/assets/video-animation-nhs-page.mp4`"
      class="img-fluid mb-4"
      style="border-radius: 120px; display: none"
      autoplay
      muted
      loop
      @loadeddata="
        (e) => {
          e.target.style.display = 'unset';
          hideSpinner('animation');
        }
      "
    ></video>

    <img
      v-else
      :src="`${urlBucket}/form/assets/banner-nhs-page.png`"
      class="w-100 mb-4"
      @load="
        (e) => {
          e.target.style.display = 'unset';
          hideSpinner('animation');
        }
      "
      style="border-radius: 120px; display: none"
      alt=""
    />

    <div id="animation" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>

    <!-- Who would you like to update -->
    <h5 class="fs-la">Who would you like to update?</h5>
    <div class="d-flex mb-4">
      <div
        :class="`box-answers-services me-2 ${informNHS ? 'checked' : ''}`"
        @click="updateValue('informNHS', !informNHS)"
      >
        <img
          :src="`${urlBucket}/form/assets/NHS.svg`"
          width="40"
          alt="NHS"
          style="display: none"
          @load="
            (e) => {
              e.target.style.display = 'unset';
              hideSpinner(`nhs`);
            }
          "
        />
        <div :id="`nhs`" class="text-center my-2">
          <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
        </div>
        <p class="mb-0 fs-md">NHS</p>
      </div>
      <!-- <div :class="`box-answers-services me-2 ${GP ? 'checked' : ''}`" @click="updateService('GP')">
        <img :src="require('@/assets/gp.png')" width="40" alt="GP" />
        <p class="mb-0 fs-md">GP</p>
      </div> -->
      <div
        :class="`box-answers-services me-2 ${informDentist ? 'checked' : ''}`"
        @click="updateValue('informDentist', !informDentist)"
      >
        <img
          :src="`${urlBucket}/form/assets/icons/dentist.svg`"
          width="40"
          alt="Dentist"
          style="display: none"
          @load="
            (e) => {
              e.target.style.display = 'unset';
              hideSpinner(`dentist`);
            }
          "
        />
        <div :id="`dentist`" class="text-center my-2">
          <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
        </div>
        <p class="mb-0 fs-md">Dentist</p>
      </div>
    </div>

    <!-- Who is your dentist -->
    <div v-if="informDentist">
      <h5 class="fs-la">Which dental surgery are you currently registered at?</h5>
      <!-- <div>
        <input
          type="text"
          placeholder="Search dentist e.g. 'Blairs Dental'"
          class="p-3 ps-5 w-100 mb-4"
        />
        <i class="fa-solid fa-magnifying-glass icon-search"></i>
      </div> -->

      <div class="input-search-container mb-3" v-if="!dentistManual">
        <div class="container-search-icon">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <vSelect
          class="w-100 fs-md"
          label="dentist"
          :clearable="false"
          :options="dentists"
          :placeholder="`Search dentist e.g. 'Blairs Dental'`"
          :multiple="false"
          @option:selected="(ev) => selectChange(ev)"
          @search="onSearch"
          ><!-- :dropdown-should-open="() => true"  -->
          <template slot="no-options">
            <div class="text-center">
              <img :src="require('@/assets/binoculars.png')" class="mb-3" alt="" />

              <p class="mb-1">We didn't find anything for '{{ search }}'</p>
              <p
                class="enter-manually"
                @click="
                  () => {
                    updateValue('dentistManual', true);
                    deleteDentist(0);
                  }
                "
              >
                Enter manually
              </p>
            </div>
          </template>
          <template slot="option" slot-scope="option">
            {{ option.dentist }}
          </template>
        </vSelect>
      </div>

      <!-- Dentist Manual -->
      <div class="mb-1" v-if="dentistManual">
        <!-- <h5 class="fs-la">Which is the dentist you'd like us to inform?</h5> -->
        <input
          class="mb-1 p-3 w-100 fs-md"
          type="text"
          placeholder="Who is your dentist?"
          :value="dentistName"
          @input="(e) => updateValue('dentistName', e.target.value)"
        />
        <!-- <p
          class="mb-0 fs-xs text-end text-decoration-underline c-pointer"
          @click="() => updateValue('dentistManual', false)"
        >
          Enter a Dentist from the list
        </p> -->
        <!-- <p v-if="errors.email" style="color: #c64a45">* Enter a valid email address</p> -->
      </div>

      <p class="mb-0 fs-md" v-if="errors.dentistsSelected" style="color: #c64a45">
        * This field is required
      </p>

      <div class="chip-list-wrapper mb-2">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in dentistsSelected"
          :key="index"
          @click="deleteDentist(index)"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item }}</p>
        </div>
      </div>

      <!-- Enter your dentist manually -->
      <div class="row mb-4">
        <div class="col d-block text-end">
          <label class="switch mb-2">
            <input
              type="checkbox"
              @change="(payload) => updateValue('dentistManual', payload.target.checked)"
              :checked="dentistManual"
            />
            <span class="slider round"></span>s
          </label>

          <p class="mb-0 fs-xs">Enter your dentist manually</p>
        </div>
      </div>

      <!--Help finding a new Dentist  -->
      <h5 class="fs-la">Do you need help finding a new dentist?</h5>
      <div class="d-flex mb-4">
        <div
          :class="`box-answers fs-md ${isSearchingForDentist === true ? 'checked' : ''}`"
          @click="updateValue('isSearchingForDentist', true)"
        >
          Yes
        </div>
        <div
          :class="`box-answers fs-md ${isSearchingForDentist === false ? 'checked' : ''} ms-2`"
          @click="updateValue('isSearchingForDentist', false)"
        >
          No
        </div>
      </div>

      <!-- <div class="d-flex" v-if="isSearchingForDentist">
        <div class="label-done mb-4 px-2 ">
         <p class="mb-0 text-black fw-bold">Excellent, we'll send help you find a dentist 🎉</p>
        </div>
      </div> -->
    </div>

    <!-- NHS -->
    <div v-if="informNHS">
      <h5 class="fs-la">Which GP surgery are you currently registered at?</h5>
      <!-- <div>
        <input
          type="text"
          placeholder="Search dentist e.g. 'Blairs Dental'"
          class="p-3 ps-5 w-100 mb-4"
        />
        <i class="fa-solid fa-magnifying-glass icon-search"></i>
      </div> -->

      <div class="input-search-container mb-3" v-if="!gpsManual">
        <div class="container-search-icon">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <vSelect
          class="w-100 fs-md"
          label="gps"
          :clearable="false"
          :options="gpsArray"
          :placeholder="`Which is the GP...`"
          :multiple="false"
          @option:selected="(ev) => selectChangeGPS(ev)"
          @search="onSearch"
        >
          <template slot="no-options">
            <div class="text-center">
              <img :src="require('@/assets/binoculars.png')" class="mb-3" alt="" />

              <p class="mb-1">We didn't find anything for '{{ search }}'</p>
              <p
                class="enter-manually"
                @click="
                  () => {
                    updateValue('gpsManual', true);
                    deleteGPS(0);
                  }
                "
              >
                Enter manually
              </p>
            </div>
          </template>
          <template slot="option" slot-scope="option">
            {{ option.gps }}
          </template>
        </vSelect>
      </div>

      <!-- GPS Manual -->
      <div class="mb-1" v-if="gpsManual">
        <!-- <h5 class="fs-la">Which is the dentist you'd like us to inform?</h5> -->
        <input
          class="mb-1 p-3 w-100 fs-md"
          type="text"
          placeholder="Which is the GP..."
          :value="gpName"
          @input="(e) => updateValue('gpName', e.target.value)"
        />
        <!-- <p
          class="mb-0 fs-xs text-end text-decoration-underline c-pointer"
          @click="() => updateValue('gpsManual', false)"
        >
          Enter a GPS from the list
        </p> -->
        <!-- <p v-if="errors.email" style="color: #c64a45">* Enter a valid email address</p> -->
      </div>
      <!--  -->
      <p class="mb-0 fs-md" v-if="errors.gpsSelected" style="color: #c64a45">
        * This field is required
      </p>

      <div class="chip-list-wrapper mb-2">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in gpsSelected"
          :key="index"
          @click="deleteGPS(index)"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item }}</p>
        </div>
      </div>

      <!-- Enter your GP manually -->
      <div class="row mb-4">
        <div class="col d-block text-end">
          <label class="switch mb-2">
            <input
              type="checkbox"
              @change="(payload) => updateValue('gpsManual', payload.target.checked)"
              :checked="gpsManual"
            />
            <span class="slider round"></span>s
          </label>

          <p class="mb-0 fs-xs">Enter your GP manually</p>
        </div>
      </div>
      <!-- selecting New GP -->
      <h5 class="fs-la">Do you need help selecting a new GP?</h5>
      <div class="d-flex mb-4">
        <div
          :class="`box-answers fs-md ${isSearchingForGP === true ? 'checked' : ''}`"
          @click="updateValue('isSearchingForGP', true)"
        >
          Yes
        </div>
        <div
          :class="`box-answers fs-md ${isSearchingForGP === false ? 'checked' : ''} ms-2`"
          @click="updateValue('isSearchingForGP', false)"
        >
          No
        </div>
      </div>

      <!-- <div class="d-flex" v-if="isSearchingForGP">
        <div class="label-done mb-4 px-2 ">
         <p class="mb-0 text-black fw-bold">Excellent, we'll send help you find a GP 🎉</p>
        </div>
      </div> -->
    </div>
    <!-- Label Pages Bottom -->
    <div class="">
      <div
        class="label-pages d-flex p-3 align-items-center"
        v-if="
          ((informNHS && gpName) || (informDentist && dentistName)) &&
          !(isSearchingForGP || isSearchingForDentist)
        "
      >
        <img :src="require('@/assets/check-y.png')" width="30px" alt="" class="ms-2 me-2" />
        <p class="ms-1 fs-sm mb-0">Excellent, we'll update your address</p>
      </div>

      <div
        class="label-pages d-flex p-3 align-items-center"
        v-if="informNHS && gpName && isSearchingForGP && !isSearchingForDentist"
      >
        <img :src="require('@/assets/check-y.png')" width="30px" alt="" class="ms-2 me-2" />
        <p class="ms-1 fs-sm mb-0">
          Excellent, we'll update your address and help you find a new GP 🎉
        </p>
      </div>

      <div
        class="label-pages d-flex p-3 align-items-center"
        v-if="informDentist && dentistName && !isSearchingForGP && isSearchingForDentist"
      >
        <img :src="require('@/assets/check-y.png')" width="30px" alt="" class="ms-2 me-2" />
        <p class="ms-1 fs-sm mb-0">
          Excellent, we'll update your address and help you find a new dentist 🎉
        </p>
      </div>

      <div
        class="label-pages d-flex p-3 align-items-center"
        v-if="
          informDentist &&
          dentistName &&
          isSearchingForDentist &&
          informNHS &&
          gpName &&
          isSearchingForGP
        "
      >
        <img :src="require('@/assets/check-y.png')" width="30px" alt="" class="ms-2 me-2" />
        <p class="ms-1 fs-sm mb-0">
          Excellent, we'll update your address and help you find a new dentist & GP 🎉
        </p>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import { navigateTo } from "../router/index";
import { isMobile } from "../utils/isMobile";
import { setRoute } from '../router/setRoute.js';
// @ is an alias to /src

export default {
  name: "PageGeneralInfo2",
  components: { vSelect },
  data() {
    return {
      isMobile,
      errors: {
        dentistsSelected: false,
        gpsSelected: false,
      },
      search: "",
      dentists: [],
      dentistsSelected: [],
      gpsArray: [],
      gpsSelected: [],
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    validateInputs() {
      if (this.dentistName) {
        this.errors.dentistsSelected = false;
      } else {
        this.errors.dentistsSelected = this.informDentist ? true : false;
      }

      if (this.gpName) {
        this.errors.gpsSelected = false;
      } else {
        this.errors.gpsSelected = this.informNHS ? true : false;
      }
    },
    updateValue(itemName, value) {
      // console.log(value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    searchingForGP(value) {
      this.$store.commit("formStore/setField", [`isSearchingForGP`, value]);
    },
    getDentists() {
      fetch(this.baseURL + "/dentists/fetch?postcode=" + this.moveFromPostCode, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const dentists = [];
          data.forEach((dentist) => {
            if (dentist.name !== "") {
              dentists.push(dentist.name);
            }
          });

          this.dentists = [...new Set(dentists)].sort();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGPS() {
      fetch(
        `${this.baseURL}/GPS/fetch?moveFromPostcode=${this.moveFromPostCode}&moveToPostcode=${this.moveToPostCode}`,
        //'http://127.0.0.1:8090/GPS/fetch?postcode='+oldPostcode,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const gpsArray = [];
          data.forEach((gps) => {
            if (gps.name !== "") {
              gpsArray.push(gps.name);
            }
          });

          this.gpsArray = [...new Set(gpsArray)].sort();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateService(variable) {
      this[`${variable}`] = !this[`${variable}`];
    },
    onSearch(search, loading) {
      // loading(true)
      this.search = search;
    },
    selectChange(value) {
      // console.log("selectChange", value);
      this.dentistsSelected = [];
      if (!this.dentistsSelected.includes(value)) {
        this.dentistsSelected.push(value);
        this.$store.commit("formStore/setField", [`informDentist`, true]);
        this.$store.commit("formStore/setField", [`dentistName`, value]);
      }
    },
    selectChangeGPS(value) {
      // console.log("selectChange", value);
      this.gpsSelected = [];
      if (!this.gpsSelected.includes(value)) {
        this.gpsSelected.push(value);
        this.$store.commit("formStore/setField", [`informNHS`, true]);
        this.$store.commit("formStore/setField", [`gpName`, value]);
      }
    },
    deleteDentist(index) {
      this.dentistsSelected.splice(index, 1);
      // this.$store.commit("formStore/setField", [`informDentist`, false]);
      this.$store.commit("formStore/setField", [`dentistName`, undefined]);
    },
    deleteGPS(index) {
      this.gpsSelected.splice(index, 1);
      // this.$store.commit("formStore/setField", [`informNHS`, false]);
      this.$store.commit("formStore/setField", [`gpName`, undefined]);
    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "Updating the NHS "]);
    this.dentistsSelected = this.dentistName ? [this.dentistName] : [];
    this.getDentists();

    this.gpsSelected = this.gpName ? [this.gpName] : [];

    this.getGPS();

    const validatePage = () => {
      // let route = "/about-you";
      // const setRoute = () => {

      //   if (this.informInsurance) {
      //     route = "/insurance";
      //     return;
      //   }

      //   if (this.informPets) {
      //     route = "/pets";
      //     return;
      //   }

      //   if (
      //     this.informPension ||
      //     this.informCharities ||
      //     this.informGyms ||
      //     this.informHomecare ||
      //     this.informBreakdown
      //   ) {
      //     route = "/general-info-3";
      //     return;
      //   }

      //   if (this.informBanks) {
      //     route = "/banks";
      //     return;
      //   }

      //   if (this.informCouncilTax || this.informWaterSewerage || this.informEnergy) {
      //     route = "/councils";
      //     return;
      //   }

      //   if (this.informMobile) {
      //     route = "/mobile-providers";
      //     return;
      //   }

      //   if (this.informLoyalty) {
      //     route = "/loyalties";
      //     return;
      //   }

      // };

      // setRoute();

      this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(setRoute(this.state));
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "moveFromPostCode",
      "moveToPostCode",
      "referrer",
      "baseURL",
      "urlBucket",
      "informPension",
      "informCouncilTax",
      "informWaterSewerage",
      "informCharities",
      "informGyms",
      "informBanks",
      "informLoyalty",
      "informMobile",
      "informInsurance",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "dentistName",
      "dentistManual",
      "gpsManual",
      "gpName",
      "informNHS",
      "informDentist",
      "informEnergy",
      "isSearchingForGP",
      "isSearchingForDentist",
      "informOpticians",
    ]),
    ...mapGetters("formStore", ["state"]),
  },
};
</script>
<style lang="scss">
.page-general-info-2 {
  .icon-search {
    color: #8a8a8a;
    float: left;
    margin-left: 15px;
    margin-top: -65px;
    position: relative;
    z-index: 2;
    font-size: 23px;
  }

  .box-answers {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 14px 34px;
    text-align: center;
    cursor: pointer;
  }

  .box-answers-services {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    width: 110px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .checked {
    border: 1px solid #2ec989;
    background-color: #1e9d68;
    color: white;
  }

  .v-select {
    color: black;
    font-weight: 600;

    .vs__actions {
      margin-right: 5px;
    }

    .vs__dropdown-toggle {
      height: 55px;
      border: none;
    }

    .vs__search::placeholder {
      color: #8a8a8a;
    }
    .vs__dropdown-menu {
      p {
        font-weight: 200;
      }
    }

    .enter-manually {
      cursor: pointer;
      font-size: 12px;
      color: #8a8a8a;
      text-decoration: underline;
    }
  }

  .chip-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .chip {
      display: flex;
      align-items: center;
      padding: 7px;
      height: 30px;
      border-radius: 25px;
      background-color: #f1f1f1;

      p {
        color: black;
        font-size: 14px;
      }

      .fa-circle-check {
        color: #1e9d68;
        font-size: 16px;
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(35px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}
</style>
